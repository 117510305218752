
import { defineComponent, PropType, computed, Ref } from 'vue'


export default defineComponent({
  name: "OctaveView",
  props: {
    c: {
      type: Number,
      required: true,
    },
    notesOn: {
      type: Set as PropType<Set<number>>,
      required: true,
    },
    offsetX: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const keyState: Ref<boolean[]> = computed(() => {
      return [...Array(12).keys()].map((i) => {
        return props.notesOn.has(props.c + i);
      });
    });
    return {
      keyState,
    }
  },
})
