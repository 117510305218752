
import { defineComponent, PropType, computed } from 'vue'
import OctaveView from "./KeyboardView/OctaveView.vue"

export default defineComponent({
  name: "KeyboardView",
  components: { OctaveView },
  props: {
    notesOn: {
      type: Set as PropType<Set<number>>,
      required: true,
    },
    octaves: {
      type: Number,
      required: true,
    },
    lowestC: {
      type: Number,
      required: true,
    }
  },
  setup(props) {
    const octavesAsArray = computed(() => {
      return [...new Array(props.octaves)].map((_, i ) => i);
    });
    return {
      octavesAsArray,
    }
  },
})
