import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["transform"]
const _hoisted_2 = ["x", "fill"]
const _hoisted_3 = ["x", "fill"]
const _hoisted_4 = ["x", "fill"]
const _hoisted_5 = ["x", "fill"]
const _hoisted_6 = ["x", "fill"]
const _hoisted_7 = ["x", "fill"]
const _hoisted_8 = ["x", "fill"]
const _hoisted_9 = ["x", "fill"]
const _hoisted_10 = ["x", "fill"]
const _hoisted_11 = ["x", "fill"]
const _hoisted_12 = ["x", "fill"]
const _hoisted_13 = ["x", "fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("g", {
    transform: `translate(${_ctx.offsetX},0)`
  }, [
    _createElementVNode("g", null, [
      _createElementVNode("rect", {
        x: 22.5 * 0,
        y: "0",
        width: "22.5",
        height: "150",
        fill: _ctx.keyState[0] ? '#f00' : '#fff',
        stroke: "#000"
      }, null, 8, _hoisted_2),
      _createElementVNode("rect", {
        x: 22.5 * 1,
        y: "0",
        width: "22.5",
        height: "150",
        fill: _ctx.keyState[2] ? '#f00' : '#fff',
        stroke: "#000"
      }, null, 8, _hoisted_3),
      _createElementVNode("rect", {
        x: 22.5 * 2,
        y: "0",
        width: "22.5",
        height: "150",
        fill: _ctx.keyState[4] ? '#f00' : '#fff',
        stroke: "#000"
      }, null, 8, _hoisted_4),
      _createElementVNode("rect", {
        x: 22.5 * 3,
        y: "0",
        width: "22.5",
        height: "150",
        fill: _ctx.keyState[5] ? '#f00' : '#fff',
        stroke: "#000"
      }, null, 8, _hoisted_5),
      _createElementVNode("rect", {
        x: 22.5 * 4,
        y: "0",
        width: "22.5",
        height: "150",
        fill: _ctx.keyState[7] ? '#f00' : '#fff',
        stroke: "#000"
      }, null, 8, _hoisted_6),
      _createElementVNode("rect", {
        x: 22.5 * 5,
        y: "0",
        width: "22.5",
        height: "150",
        fill: _ctx.keyState[9] ? '#f00' : '#fff',
        stroke: "#000"
      }, null, 8, _hoisted_7),
      _createElementVNode("rect", {
        x: 22.5 * 6,
        y: "0",
        width: "22.5",
        height: "150",
        fill: _ctx.keyState[11] ? '#f00' : '#fff',
        stroke: "#000"
      }, null, 8, _hoisted_8)
    ]),
    _createElementVNode("g", null, [
      _createElementVNode("rect", {
        x: 22.5 * 0 + (22.5 - 10),
        y: "0",
        width: "15",
        height: "97",
        fill: _ctx.keyState[1] ? '#f00' : '#000',
        stroke: "#000"
      }, null, 8, _hoisted_9),
      _createElementVNode("rect", {
        x: 22.5 * 1 + (22.5 - 5),
        y: "0",
        width: "15",
        height: "97",
        fill: _ctx.keyState[3] ? '#f00' : '#000',
        stroke: "#000"
      }, null, 8, _hoisted_10),
      _createElementVNode("rect", {
        x: 22.5 * 3 + (22.5 - 10),
        y: "0",
        width: "15",
        height: "97",
        fill: _ctx.keyState[6] ? '#f00' : '#000',
        stroke: "#000"
      }, null, 8, _hoisted_11),
      _createElementVNode("rect", {
        x: 22.5 * 4 + (22.5 - 7),
        y: "0",
        width: "15",
        height: "97",
        fill: _ctx.keyState[8] ? '#f00' : '#000',
        stroke: "#000"
      }, null, 8, _hoisted_12),
      _createElementVNode("rect", {
        x: 22.5 * 5 + (22.5 - 3.5),
        y: "0",
        width: "15",
        height: "97",
        fill: _ctx.keyState[10] ? '#f00' : '#000',
        stroke: "#000"
      }, null, 8, _hoisted_13)
    ])
  ], 8, _hoisted_1))
}