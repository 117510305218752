import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-495ed6e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "keyboard-container" }
const _hoisted_2 = ["viewBox"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OctaveView = _resolveComponent("OctaveView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", {
      viewBox: `0 0 ${157.5 * _ctx.octaves} 150`
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.octavesAsArray, (i) => {
        return (_openBlock(), _createBlock(_component_OctaveView, {
          offsetX: 157.5 * i,
          c: _ctx.lowestC + 12 * i,
          notesOn: _ctx.notesOn,
          key: i
        }, null, 8, ["offsetX", "c", "notesOn"]))
      }), 128))
    ], 8, _hoisted_2))
  ]))
}